<template>
    <div>
        <!--AxGdCommentDetail.vue-->
        <el-container>
            <el-main style="height: 600px;">
                <el-row>
                    <el-col :span="20">
                        <el-button-group>
                            <el-button type="" size="mini" @click="sysdetailadd">新增</el-button>
                            <el-button type="" size="mini" @click="sysdetailupdate">修改</el-button>
                            <el-button type="" size="mini" @click="sysdetailsave">保存</el-button>
                        </el-button-group>
                    </el-col>
                </el-row>
                <vxe-form title-align="right" title-width="140">
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="采购单号" field="code" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].code" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].code" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="物料编号" field="invcode" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].invcode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].invcode" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="单据信息" field="othercode" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].othercode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].othercode" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="datein" field="datein" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].datein" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].datein" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                </vxe-form>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_gd_commentdetail",
        extends: ax.control1(),
        data() {
            return {
                vouchertype: "detail",
                mastertable: "ax_gd_comment",
                model: "zb",
                settings: "axGdComment",
                datainit: {
                    "ax_gd_comment": {
                        "keyname": "ax_gd_comment",
                        "key": "guid",
                        "ptable": "",
                        "pkey": "",
                        "relation": "",
                        "datatype": "datafree",
                        "dataname": "主表",
                        "jsexps": [],
                        "hasseq": true,
                        "rows": [{
                               code:"",
                               invcode:"",
                               othercode:"",
                               datein:"",
                        }]
                    }
                }
            }
        },
    }
</script>